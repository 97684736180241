// config/settingsConfig.js
const handleChangeValue = (key, value, setSettingsValue, setApiBody) => {
  setSettingsValue((prev) => ({ ...(prev || []), [key]: value }));
  setApiBody((prev) => [...prev, key]);
};

const handleChildrenAttributeValueChange = (parentKey, key, value, setSettingsValue, setApiBody) => {
  setSettingsValue((prev) => ({
    ...(prev || []),
    [parentKey]: { ...(prev?.[parentKey] || []), [key]: value },
  }));
  setApiBody((prev) => [...prev, parentKey]);
};

export const getSettingsConfig = (setSettingsValue, setApiBody, appProperties) => [
  {
    key: "loggingPreference",
    title: "Logging Preference",
    description:
      "Specify your preferred method for logging SMS activities to ensure accurate record-keeping and efficient management. If you plan to activate automations for incoming messages, we recommend using the Activities logging method",
    label: "How would you like to log sent and received SMS?",
    type: "radio",
    options: [
      { label: "Notes", value: "notes" },
      { label: "Activities", value: "activities" },
    ],
    onchange: (key, value) => handleChangeValue(key, value, setSettingsValue, setApiBody),
  },
  {
    key: "sendEmail",
    parentKey: "emailNotification",
    title: "Email notification",
    description:
      "Configure your settings to receive email notifications for inbound SMS messages. Enter a email address to ensure you get these alerts promptly",
    type: "switch",
    label: "Would you like to enable email notifications for inbound SMS?",
    onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
    additionalFields: [
      {
        text: "Send email to ",
      },
      {
        key: "recordOwner",
        type: "checkbox",
        label: "Record Owner",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
      {
        key: "accountOwner",
        type: "checkbox",
        label: `Account Owner (${appProperties?.extensionInstalledUserEmailId || ""})`,
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
      },
      {
        key: "customEmail",
        type: "checkbox",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
        label: "Additional Email Addresses",
      },
      {
        key: "customEmailList",
        placeholder: "Enter an email address",
        type: "email",
        onchange: (parentKey, key, value) => handleChildrenAttributeValueChange(parentKey, key, value, setSettingsValue, setApiBody),
        label: "Please provide a email address for these notifications.",
      },
    ],
  },
  {
    key: "defaultCountryCode",
    parentKey: "defaultCountryCode",
    title: "Country Code",
    description:
      "Select a default country code to be automatically applied when a prospect’s phone number does not have a country code. This ensures consistent communication and correct phone number formatting",
    type: "select",
    label: "Country Code",
    tag: "The country code will appear as the default in all locations.",
  },
];
