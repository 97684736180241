import ZohoCRM from "./ZohoCRMController";
import { ZOHO_CRM_API_VARIABLE_NAME } from "./../constants/AppConstants";

const ZohoCRMEmbedded = {
  init: function () {
    return new Promise((resolve, reject) => {
      console.log(":::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 1111 ----", ZohoCRM.selectedEntityModuleFromZohoCRM);
      window?.ZOHO?.embeddedApp.on("PageLoad", function (data) {
        ZohoCRM.selectedEntityModuleFromZohoCRM = data?.Entity;
        if (data?.module) {
          ZohoCRM.selectedEntityModuleFromZohoCRM = data?.module;
        }

        ZohoCRM.selectedEntityIdsFromZohoCRM = data?.EntityId;
        console.log(":::::::::::: handleFetchTemplates dinku embeddedApp :::::::::: 33333 ----", data);
        if (data?.EntityId) {
          ZohoCRM.serviceInitialized = true;
        }
        ZohoCRM.configData = data?.configdata?.parameters;
        resolve(data);
      });
      window?.ZOHO?.embeddedApp?.init().then(function () {
        console.log(":::::::::::: handleFetchTem plates dinku embeddedApp :::::::::: 2222 ----");
      });
    });
  },
  setWebhook: function (webhookUrl, webhookId, appProperties, integId) {
    return new Promise((resolve, reject) => {
      console.log("dtiou >>> inside >> webhookUrl", webhookUrl);
      console.log("dtiou >>> inside >> webhookId", webhookId);

      console.log("dtiou >>> inside >> integId", integId);
      var parameters = {
        selectedId: "${!" + appProperties.module + ".Id}",
        module: appProperties.module,
        eventId: webhookId,
        integId: integId,
      };
      var customActionValueToBeStored = {
        parameters: JSON.stringify(parameters),
      };

      console.log("dtiou >>> inside >> customActionValueToBeStored", customActionValueToBeStored);
      customActionValueToBeStored.url = webhookUrl;

      console.log("dtiou customActionValueToBeStored >>>>>>>>>>", JSON.stringify(customActionValueToBeStored));
      window.ZOHO.CRM.ACTION.setConfig(customActionValueToBeStored);
    });
  },
  getWebhook: function () {
    return new Promise((resolve, reject) => {
      console.log(":::::::::::: handleFetchTemplates dtiou ZohoCRM.configData :::::::::: 33333 ----", ZohoCRM.configData);
      resolve(ZohoCRM.configData);
    });
  },
  getUserData: function () {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.CONFIG.getCurrentUser()
        .then(function (data) {
          window.ZOHO.CRM.CONFIG.getOrgInfo()
            .then(function (dataOrg) {
              var companyId = dataOrg.org[0].zgid;
              var emailId = data.users[0].email;
              var userId = data.users[0].zuid;
              var companyOrgId = companyId;

              var userData = {
                companyId: companyId,
                userId: userId,
                companyOrgId: companyOrgId,
                emailId: emailId,
              };
              resolve(userData);
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          console.log("handleFetchTemplates getUserData catch >>>>>>>>>>", err);
        });
    }).catch(function (err) {
      console.log("handleFetchTemplates whole catch >>>>>>>>>>", err);
    });
  },
  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  },
  saveApplicationToken: function (hashToBeSaved) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          window.ZOHO.CRM.CONFIG.getCurrentUser()
            .then(function (data) {
              var userId = data.users[0].zuid;
              let promiseArray = [];
              var userHashJson = {};
              if (typeof userHashObjectStoredFromZohoCRM == "string") {
                try {
                  userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
                } catch (e) {
                  console.log(e);
                }
              }

              if (userHashJson === undefined || userHashJson === "") {
                userHashJson = {};
              }
              userHashJson[userId] = hashToBeSaved;
              var customVariableJson = {
                apiname: customVariableApiName,
                value: userHashJson,
              };

              promiseArray.push(saveCRMData(customVariableJson));
              Promise.all(promiseArray).then(function () {
                resolve();
              });
            })
            .catch(function (err) {
              reject(err);
            });
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  get: function (key) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.API.getOrgVariable(key)
        .then(function (response) {
          if (typeof response == "string") {
            response = JSON.parse(response);
          }
          var successResp = response.Success;
          if (typeof successResp == "string") {
            successResp = JSON.parse(successResp);
          }

          var contentObj = successResp?.Content;
          if (contentObj) {
            resolve(contentObj);
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  getApplicationToken: function (key) {
    return new Promise((resolve, reject) => {
      var customVariableApiName = ZOHO_CRM_API_VARIABLE_NAME + "hash";
      ZohoCRMEmbedded.get(customVariableApiName)
        .then(function (userHashObjectStoredFromZohoCRM) {
          if (userHashObjectStoredFromZohoCRM) {
            window.ZOHO.CRM.CONFIG.getCurrentUser()
              .then(function (data) {
                var userId = data.users[0].zuid;
                var userHashJson = JSON.parse(userHashObjectStoredFromZohoCRM);
                if (userId in userHashJson) {
                  var hash = userHashJson[userId];
                  resolve(hash);
                } else {
                  resolve("");
                }
              })
              .catch(function (err) {
                reject(err);
              });
          } else {
            resolve("");
          }
        })
        .catch(function (err) {
          console.log("h1s-token-zoho-crm :: yet-to-save :: getApplicationToken ::  final catch  else 2ee>>>>>", err);
          resolve("");
        });
    });
  },
};

function saveCRMData(parameterMap) {
  return new Promise((resolve, reject) => {
    window.ZOHO.CRM.CONNECTOR.invokeAPI("crm.set", parameterMap)
      .then(function (data) {
        resolve(data);
      })
      .catch(function (err) {
        console.log("hs-token-zoho-crm :: saveCRMData >>>>>> ERRROR>>>>>>> saved_after_err", err);
      });
  });
}

export default ZohoCRMEmbedded;
